import React , { Component } from 'react';
import './App.css';
import LoginPage from './components/LoginPage';
import MainPage from './components/MainPage';
import LoginService from './serivces/loginService';

class App extends Component {

  constructor(pros) {
    super(pros);
    this.loginService = new LoginService();
  }

  componentDidMount() {
    this.loginService.getCurrent().then((rs) => {
      if (rs == null) {
        this.setState({loginSuccess: false});
        return;
      }
      this.setState({loginSuccess: true});
    });
  }

  state = { loginSuccess : false }

  loginCompleted = (status = true)=> {
    this.setState({loginSuccess: status});
  }

  render() { 
    if (!this.state.loginSuccess) {
      return <LoginPage onLoginCompleted={this.loginCompleted}/>
    }
    return <MainPage gotoLogin={() => this.loginCompleted(false)}></MainPage>;
  }
}
 
export default App;