import requestHelper from "../utils/requestHelper";

class ABSService {
    constructor(name) {
        this.URL = `/api/${name}`;
        this.requestHelper = requestHelper;
    }

    getList = async(query) => {
        const data = await requestHelper.get(this.URL, query);
        return data;
    }

    getById = async(id) => {
        const data = await requestHelper.get(`${this.URL}/${id}`);
        return data;
    }

    create = async(data) => {
        return await requestHelper.put(this.URL, data);
    }

    update = async(data) => {
        return await requestHelper.post(this.URL, data);
    }

    delete = async(id) => {
        return await requestHelper.delete(`${this.URL}/${id}`);
    }
}

export default ABSService;