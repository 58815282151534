import ABSService from "./absService";

class LoginService extends ABSService {
    constructor() {
        super("");
    }

    login = async (loginData) => {
        let loginResult = this.requestHelper.post('/api/login', loginData);
        return loginResult;
    }

    logout = async () => {
        let loginResult = this.requestHelper.post('/api/logout');
        return loginResult;
    }

    getCookieToken() {
        const cookie = this.requestHelper.parseCookie();
        return cookie.token;
    }

    setToken = async(token) => {
        this.requestHelper.setToken(token);
    }

    getCurrent = async () => {
        let loginResult = this.requestHelper.get('/api/current');
        return loginResult;
    }
}

export default LoginService;