import axios from "axios";
import {SERVER_URL, PUBLIC_URL, LOGIN_URL, SERVER_DOMAIN} from './cons'


class RequestHelper {
    constructor() {
        this.BASE_URL = SERVER_URL + PUBLIC_URL;
        this.conf = null;
        this.token = null;
    }

    parseCookie() {
        return document.cookie.split('; ').reduce((prev, current) => {
            const [name, ...value] = current.split('=');
            prev[name] = value.join('=');
            return prev;
        }, {});
    }

    setToken(token) {
        const cookieName = 'token';
        var myDate = new Date();
        myDate.setDate(myDate.getDate + 3);
        document.cookie = `${cookieName}=${token};expires=${myDate};donamin=.${SERVER_DOMAIN};path=/`;
        //document.cookie = `${cookieName}=${token};expires=${myDate};donamin=.localhost:3000;path=/`;
        //this.token = token;
        this.conf = {headers: {Authorization: `Bearer ${token}`}};
    }

    /**
     * 
     * @param {function} func hàm gọi
     * @param {array} args danh sách tham số
     * @returns giá trị nhận được từ server
     */
    wrapperExcute = async (func, args) => {
        const masks = document.getElementById('mask');
        try {
            if (this.startRequest) this.startRequest();
            masks.style.visibility = "visible";
            const cookie = this.parseCookie();
            let axiosConfig = {};
            if (cookie.token) {
                axiosConfig.headers = {
                    'authorization': 'Bearer ' + cookie.token,
                }
            }
            let response = await func(...args, axiosConfig);
            return response.data;
        } catch(ex) {
            this.handleError(ex);
            return null;
        }
        finally {
            masks.style.visibility = "hidden";
            if (this.stopRequest) this.stopRequest();
        }
    }

    handleError(ex) {
        if (ex instanceof EvalError) {
            window.location.href = LOGIN_URL;
        }
        if (ex.response.status === 401) {
            //window.location.href = LOGIN_URL;
            return;
        }
        if (ex.response.status === 404) {
            this.onError("Xin lỗi! Đường dẫn truy cập không tồn tại!");
            return;
        }
        if (ex.response.status === 500) {
            this.onError("Xin lỗi! Có lỗi hệ thống. Vui lòng thông báo với quản trị viên để biết thông báo về lỗi này.");
            return;
        }
        this.onError(ex.message);
        //throw ex;
    }

    /**
     * Get an object by url
     * @param {String} url url
     * @param {Object} query object query
     * @returns get json data
     */
    get = async (url, query=null) => {
        var queryString = query == null ? "" : Object.keys(query).map(key => key + '=' + query[key]).join('&');
        queryString = !queryString ? "" : '?' + queryString;
        return await this.wrapperExcute(axios.get, [this.BASE_URL + url + queryString]);
    }

    /**
     * Gọi api edit
     * @param {string} url đường dẫn
     * @param {Object} postData dữ liệu post
     * @returns giá trị sau khi post
     */
    post = async(url, postData) => {
        return await this.wrapperExcute(axios.post, [this.BASE_URL + url, postData]);
    }

    /**
     * Gọi api add
     * @param {string} url đường dẫn
     * @param {Object} postData dữ liệu post
     * @returns giá trị sau khi post
     */
    put = async(url, putData) => {
        return await this.wrapperExcute(axios.put, [this.BASE_URL + url, putData]);
    }

    /**
     * Gọi api delete
     * @param {string} url đường dẫn
     * @param {Object} id dữ liệu dòng muốn xoá
     * @returns giá trị sau khi post
     */
    delete = async(url) => {
        return await this.wrapperExcute(axios.delete, [this.BASE_URL + url]);
    }

    download = async(url, query) => {
        try {
            var queryString = query == null ? "" : Object.keys(query).map(key => key + '=' + query[key]).join('&');
            queryString = !queryString ? "" : '?' + queryString;
            const cookie = this.parseCookie();
            const response = await axios.get(this.BASE_URL + url + queryString, {
                headers: {
                    'Authorization': cookie.token,
                },
                responseType: 'blob'
            });
            const downloadURL = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = downloadURL;

            link.setAttribute('download', 'baocao.xlsx');
            document.body.appendChild(link);
            link.click();
        } catch (ex) {
            this.handleError(ex);
        }
    }
}

const requestHelper = new RequestHelper();

export default requestHelper;