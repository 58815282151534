import ABSService from "./absService";

class AppGroupService extends ABSService {
    constructor() {
        super("app-group");
    }

    login = async (loginData) => {
        let loginResult = this.requestHelper.post('/api/login', loginData);
        return loginResult;
    }

    goToApp = async(appId) => {
        try {
            const data = {appId};
            console.log(appId);
            const appLink = await this.requestHelper.post('/api/create-app-link', {appId});
            window.open(appLink.url);
        } catch (ex) {
            document.location.reload();
        }
    }
}

export default AppGroupService;