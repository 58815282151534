const CONS = {
    SERVER_URL: 'https://workplace.thacoagri.com.vn/',
    PUBLIC_URL: '',
    LOGIN_URL: 'https://workplace.thacoagri.com.vn/',
    SERVER_DOMAIN: 'workplace.thacoagri.com.vn'
}

export const SERVER_URL = CONS.SERVER_URL;
export const PUBLIC_URL = CONS.PUBLIC_URL;
export const LOGIN_URL = CONS.LOGIN_URL;
export const SERVER_DOMAIN = CONS.SERVER_DOMAIN;
export const CURRENT_DOMAIN = 'workplace.thaco.com.vn';
