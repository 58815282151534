import React, { Component } from 'react';
import LoginService from '../serivces/loginService';
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import Input from '@mui/material/Input';

class LoginPage extends Component {
    constructor(props) {
        super(props)
        this.service = new LoginService();
    }

    state = {
        model: {
            user: '',
            pass: ''
        },
        isLoginFaild: false,
        errors: {},
        showPassword: false,
    }
    handleClickShowPassword = () => {
        this.setState({...this.state.model.pass,showPassword: !this.state.showPassword });
    };
    handleMouseDownPassword = (ev) => {
        ev.preventDefault();
    };

    login = (ev) => {
        if (!this.validate(this.state.model.user, this.state.model.pass)) {
            return;
        }
        this.service.login(this.state.model).then((rs) => {
            if (!rs.success) {
                this.setState({ isLoginFaild: true });
            } else {
                this.service.setToken(rs.token);
                this.props.onLoginCompleted(true);
            }
        });
    }

    onChange = (ev) => {
        const model = this.state.model;
        model[ev.target.name] = ev.target.value;
        this.validate(model.user, model.pass);
        this.setState({ model });
    }

    validate = (user, pass) => {
        const errors = {};
        if (!user) errors.user = "Nhập tên đăng nhập";
        if (!pass) errors.pass = "Nhập mật khẩu";
        this.setState({ errors });
        if (errors.user || errors.pass) {
            return false;
        }
        return true;
    }

    render() {
        return (
            <div id="login-container">
                <h1 className="h2 text-light text-center push-top-bottom animation-slideDown">
                    <img width="300px" src='/img/agriculture-logo.png' />
                </h1>
                <div className="block animation-fadeInQuickInv">
                    <div className="block-title">
                        <h2>Đăng nhập</h2>

                    </div>

                    <form id="form-login" className="form-horizontal">
                        <div onChange={this.onChange} className={this.state.errors.user ? "form-group has-error" : "form-group"}>
                            <div className="col-xs-12">
                                <Input value={this.state.model.user} type="text" id="login-email" name="user" className="form-control" placeholder="Tên đăng nhập" />
                                <div className="help-block animation-pullUp">{this.state.errors.user}</div>
                            </div>
                        </div>
                        <div onChange={this.onChange} className={this.state.errors.pass ? "form-group has-error" : "form-group"}>
                            <div className="col-xs-12">
                                <Input value={this.state.model.pass} type={this.state.showPassword ? "text" : "password"} id="login-password" name="pass" className="form-control" placeholder="Mật khẩu"
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                               onClick={this.handleClickShowPassword}
                                                onMouseDown={this.handleMouseDownPassword}
                                            >
                                                {this.state.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                            </IconButton>

                                        </InputAdornment>
                                    }
                                />

                                <div className="help-block animation-pullUp">{this.state.errors.pass}</div>
                            </div>
                        </div>

                        {this.state.isLoginFaild ?
                            <div className="form-group">
                                <div className="col-xs-12">
                                    <span className="text-danger small">
                                        Tên đăng nhập, mật khẩu không hợp lệ
                                    </span>
                                </div>
                            </div>
                            :
                            ""
                        }

                        <div className="form-group form-actions">
                            <div className="col-xs-8">
                                <label className="csscheckbox csscheckbox-primary">
                                    <input type="checkbox" id="login-remember-me" name="login-remember-me" />
                                    <span></span>
                                    Ghi nhớ đăng nhập
                                </label>
                            </div>
                            <div className="col-xs-4 text-right">
                                <button type="button" onClick={this.login} className="btn btn-effect-ripple btn-sm btn-primary"><i className="fa fa-check"></i> Đăng nhập</button>
                            </div>
                        </div>
                    </form>
                </div>

                <footer className="text-muted text-center animation-pullUp">
                    <small><span id="year-copy"></span> &copy; <a href="http://goo.gl/RcsdAh" target="_blank">THACO AGRI</a></small>
                </footer>
            </div>
        );
    }
}

export default LoginPage;