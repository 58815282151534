import React, { Component } from 'react';
import AppGroupService from '../serivces/appGroupService'
import LoginService from '../serivces/loginService';

class MainPage extends Component {
    constructor(props) {
        super(props);
        this.appGroupService = new AppGroupService();
        this.loginService = new LoginService();
    }

    state = { 
        user: {},
        appGroups: [],
    }
    
    componentDidMount = () => {
        this.loginService.getCurrent().then((user) => {
            this.setState({user});
            this.appGroupService.getList().then((rs) => {
                this.setState({appGroups: rs.items});
            });
        });
    }

    logout = (ev) => {
        this.loginService.logout().then((rs) => {
            if (rs.success) {
                this.props.gotoLogin();
            }
        });
        ev.preventDefault();
    }

    goToApp = (ev, appId) => {
        this.appGroupService.goToApp(appId);
        ev.preventDefault();
    }

    render() { 
        return (
        <div id="main-container">
            <header className="navbar navbar-inverse">
                <ul className="nav navbar-nav-custom">
                    <li>
                        <a href="#">
                            <img className="img-logo" src="img/logo-white.png"/>
                        </a>
                    </li>

                    <li className="hidden-xs animation-fadeInQuick text-uppercase">
                        <a href=""><strong>Digital Workplace</strong></a>
                    </li>
                </ul>

                <ul className="nav navbar-nav-custom pull-right">
                    <li>
                        <form action="#" method="post" className="navbar-form-custom">
                            <input type="text" id="top-search" name="top-search" className="form-control" placeholder="Tìmm kiếm.."/>
                        </form>
                    </li>

                    <li className="dropdown">
                        <a href="javascript:void(0)" className="dropdown-toggle" data-toggle="dropdown">
                            <img src="img/placeholders/avatars/avatar9.jpg" alt="avatar"/>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-right">
                            <li className="dropdown-header">
                                <strong className="text-uppercase">{this.state.user.userName}</strong>
                            </li>
                            
                            <li>
                                <a href="#">
                                    <i className="fa fa-pencil-square fa-fw pull-right"></i>
                                    Thông tin
                                </a>
                            </li>
                        
                            <li>
                                <a onClick={this.logout}>
                                    <i className="fa fa-power-off fa-fw pull-right"></i>
                                    Đăng xuất
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </header>

            <div id="page-content">
                {
                    this.state.appGroups.map((appGroup) => 
                        <div key={appGroup.id} className="block full">
                            <div className="block-title">
                                <h2>{appGroup.name}</h2>
                            </div>
                            <div className="row border-bottom">
                                {
                                    appGroup.thagricoapps.map((app) => (
                                        <div key={app.id} className="col-lg-2 col-md-4 col-xs-6 push-inner-top-bottom">
                                            <a onClick={(ev) => this.goToApp(ev, app.id)} data-growl="info"  className="widget text-center remove-margin-bottom">
                                                <div className="widget-content text-center" style={{backgroundColor: app.appColor}}>
                                                    <div className="widget-icon push">
                                                        <i className={"fa text-light-op " + app.image}></i>
                                                    </div>
                                                    <div>
                                                        <strong className="text-light text-uppercase">{app.name}</strong>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>)
                                    )
                                }
                            </div>
                        </div>
                    )
                }
                <footer className="text-muted text-center animation-pullUp">
                    <p>2022 © <a href="#" target="_blank">THACO AGRICULTURE</a></p>
                </footer>
            </div>
        </div>);
    }
}
 
export default MainPage;